import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, SecondaryCallout, EndpointDisplay, NCCNcallout, TextBlock, ReferencesBlock } from '../../components';
import NMIBC from '../../components/StudyDesign/__study-design/nmibc'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-057 - Clinical Trial Results | HCP`,
    keywords: `keynote 057, high-risk non-muscle invasive bladder cancer clinical trial results`,
    description: `Health care professionals may find clinical trial results from KEYNOTE-057 in certain patients with high-risk non-muscle invasive bladder cancer (NMIBC).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nmibc/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nmibc/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠057","description":"Clinical Findings from KEYNOTE⁠-⁠057","trialDesign":"A multicenter, open-label, single-arm trial in 96 patients with high-risk NMIBC with CIS with or without papillary tumors who were BCG unresponsive and who were ineligible for or elected not to undergo cystectomy."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nmibc/","@type":"MedicalGuideline","guideline":"Pembrolizumab (KEYTRUDA) is a systemic immuno-oncology therapy recommended in NCCN Guidelines® (NCCN Category 2Ad) as a treatment option for patients with BCG-unresponsive, high-risk NMIBC with CIS with or without papillary tumors who are ineligible for or have elected not to undergo cystectomy.","recognizingAuthority":"NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)"}`
    ]
};

//from /src/util/data/indications.js
const indicationId = 17; 
const jobCode = jobCodes[17].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/nmibc/' },
                { text: 'Dosing Options', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;057'}, 
    {label: 'Study Design for KEYNOTE&#8288;-&#8288;057'}, 
    {label: 'Select NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)'},
];

const keynote057CRresponseFootnotes = [
    {
        label: 'a.',
        text: 'A CR was defined by negative results for cystoscopy (with transurethral resection of bladder tumor (TURBT)/biopsies, as applicable), urine cytology, and computed tomography urography imaging.'
    }
];

const keynote057CRresponseDefinitions = "CI = confidence interval.";

const key057SecondaryCalloutFootnotes = [
    {
        label: 'b.',
        text: 'DOR was based on patients (n=39) who achieved a CR; reflects period from the time CR was achieved.'
    }
];

const key057SecondaryCalloutDefinitions = "+ Denotes ongoing response.";

const NCCNcalloutData = {
    bodyCopy: 'Pembrolizumab (KEYTRUDA) is a systemic immuno-oncology therapy recommended in NCCN Guidelines® (NCCN Category 2A<sup>d</sup>) as a treatment option for patients with BCG-unresponsive, high-risk NMIBC with CIS with or without papillary tumors who are ineligible for or have elected not to undergo cystectomy.<sup>4</sup>',
    showBadge: false,
};

const NCCNcalloutDefinitions = `National Comprehensive Cancer Network® (NCCN®) makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.`;

const NCCNfootnotes = [
    {
        label: 'd.',
        text: 'Category 2A = Based upon lower-level evidence, there is uniform NCCN consensus that the intervention is appropriate.<sup style="top:4px;">4</sup>'
    }
]

const NCCNcalloutReferences = [
    {
        label: '4.',
        text: 'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Bladder Cancer V.5.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed October 29, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org.'
    },
];

const fiveYearFollowUpFootnotes = [
    {
        label: 'c.',
        text: `Follow-up duration was defined as the time from first dose to the date of death or the database cutoff date if the participant was still alive. Data cutoff: May 25, 2022. Data available on request from Merck Professional Services-DAP, WP1-27, PO Box 4, West Point, PA 19486-0004. Please specify information package US-NMI-00817.<sup>3</sup>`
    }
]

const pageReferences = [
    {
        label: "1.",
        text: "Adstiladrin. Package insert. Ferring Pharmaceuticals; 2024."
    },
    {
        label: "2.",
        text: "Anktiva. Prescribing information. ImmunityBio, Inc.; 2024."
    },
    {
        label: "3.",
        text: "Data available on request from Merck Professional Services-DAP, WP1-27, PO Box 4, West Point, PA 19486-0004. Please specify information package US-NMI-00817."
    },
    {
        label: "4.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Bladder Cancer V.5.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed October 29, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                        <AnchorLinks items={anchorLinkModelData} />
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud"> 
                            <SecondaryCallout 
                                title="KEYTRUDA is the only FDA-approved systemic treatment for certain patients with high-risk NMIBC who are BCG-unresponsive<sup>1,2</sup>"
                                text="KEYTRUDA, as a single agent, is indicated for the treatment of patients with BCG-unresponsive, high-risk, NMIBC with CIS with or without papillary tumors who are ineligible for or have elected not to undergo cystectomy."
                                alt="Of the 39 Responding Patients With KEYTRUDA® (pembrolizumab), 46% (n=18) Maintained the Response for ≥12 Months From the Time CR Was Achieved in KEYNOTE-057"    
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <PageSubSection title="Complete Response and Duration of Response">

                            <EndpointDisplay 
                                title="Complete Response (CR)<sup>a</sup>" 
                                limitation="In 96 patients with BCG-unresponsive, high-risk NMIBC with CIS with or without papillary tumors who were ineligible for or elected not to undergo cystectomy" 
                                footnotes={keynote057CRresponseFootnotes}
                                definitions={keynote057CRresponseDefinitions}
                                cards={
                                    [
                                        {
                                            dataType: 'CR',
                                            label: 'KEYTRUDA',
                                            rate: '41%',
                                            ci: '95% CI, 31–51',
                                            n: '39/96',                                         
                                        },
                                    ]
                                }
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: `The median follow-up time was 28.0 months (range: 4.6–40.5 months)`
                                    },
                                ]}   
                            />

                            <SecondaryCallout 
                                title="Of the 39 responding patients, 46% (n=18) maintained the response for ≥12 months from the time CR was achieved" 
                                text="Median DOR<sup>b</sup>: 16.2 months (range: 0.0+ – 30.4+ months)"
                                footnotes={key057SecondaryCalloutFootnotes}
                                definitions={key057SecondaryCalloutDefinitions}
                            />

                            </PageSubSection>


                            <TextBlock footnotes={fiveYearFollowUpFootnotes}>
                                <h4>5-Year Follow-up Data from KEYNOTE&#8288;-&#8288;057<sup>3</sup></h4>
                                <p>Median follow-up<sup>c</sup> for the 96 patients treated with KEYTRUDA was <strong>58.0 months</strong> (range: 4.6–72.5 months).</p>  
                                <p>23.1% (9/39) of the responding patients maintained a CR for ≥45 months.</p>
                                <ul>
                                    <li>For these 9 patients, the response duration ranged from 46.9 to 57.7 months.</li>
                                </ul>
                            </TextBlock>
                                

                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <NMIBC />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[2].label}>
                            <NCCNcallout references={NCCNcalloutReferences} definitions={NCCNcalloutDefinitions} footnotes={NCCNfootnotes} data={NCCNcalloutData}  />
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>
                
                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>

                    <PageSection bgColor='cloud'>
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
