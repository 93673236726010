import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const NMIBC = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview title="Study Overview" description="A multicenter, open-label, single-arm trial in 96 patients with high-risk NMIBC with CIS with or without papillary tumors who were BCG unresponsive and who were ineligible for or elected not to undergo cystectomy. BCG-unresponsive, high-risk NMIBC was defined as persistent disease despite adequate BCG therapy, disease recurrence after an initial tumor-free state following adequate BCG therapy, or T1 disease following a single induction course of BCG. Adequate BCG therapy was defined as administration of at least 5 of 6 doses of an initial induction course plus either of: at least 2 of 3 doses of maintenance therapy or at least 2 of 6 doses of a second induction course." data={[
            [
                {
                    title: 'Patient Population',
                    description: `
                        <p>96 patients with BCG-unresponsive, high-risk NMIBC with CIS with or without papillary tumors who were ineligible for or elected not to undergo cystectomy.</p>
                        <p>The trial excluded patients with muscle-invasive (ie, T2, T3, T4) locally advanced nonresectable or metastatic urothelial carcinoma, concurrent extravesical (ie, urethra, ureter, or renal pelvis) non-muscle invasive transitional cell carcinoma of the urothelium, or autoimmune disease or a medical condition that required immunosuppression.</p>
                        <p>Prior to treatment, all patients had undergone TURBT to remove all resectable disease (Ta and T1 components). Residual CIS (Tis components) not amenable to complete resection was allowed.</p>
                    `,
                    type: 'box-gradient'
                }
            ]
        ]} />
    
        <StudyDesignArms 
            title="Study Arms" 
            arms={[
                {
                    title: "KEYTRUDA",
                    data: [
                        [
                            {
                                title: 'Treatment',
                                description: `200&nbsp;mg Q3W as a 30-minute IV infusion`,
                                type: 'box-green'
                            }
                        ],
                        [
                            {
                                type: 'arrow-green'
                            }
                        ],
                        [
                            {
                                title: 'Treatment Duration',
                                description: `Treatment continued until unacceptable toxicity; persistent or recurrent high-risk NMIBC, progressive disease, or up to 24 months in patients without disease progression`
                            }
                        ]
                    ]
                }
            ]} 
        />
    
        <StudyCopyBlock 
            title="End Points"
            description={` 
                <p><strong>Tumor status assessment based on major efficacy outcome measures:</strong></p>
                <ul>
                    <li>CR (as defined by negative results for cystoscopy with TURBT/biopsies as applicable), urine cytology, and computed tomography urography imaging)</li>
                    <li>DOR</li>
                </ul>
                <p><strong>Response assessed every 12 weeks for 2 years and then every 24 weeks for 3 years.</strong></p>
            `} 
        />
    
        <StudyCopyBlock 
            title="Patient Characteristics"
            description={`
                <ul>
                    <li>ECOG PS 0: 73%</li>
                    <li>ECOG PS 1: 27%</li>
                    <li>Baseline high-risk NMIBC disease status - Persistent: 27%</li>
                    <li>Baseline high-risk NMIBC disease status - Recurrent: 73%</li>
                    <li>Median age 73 years (range: 44–92 years)
                        <ul>
                            <li>44% aged ≥75 years</li>
                            <li>84% male</li>
                            <li>67% White</li>
                        </ul>
                    </li>
                    <li>Tumor pattern at study entry was CIS with T1 (13%), CIS with high-grade Ta (25%), and CIS (63%)</li>
                    <li>The median number of prior instillations of BCG was 12</li>
                </ul>
            `} 
             definitions={`
                ECOG PS = Eastern Cooperative Oncology Group performance status; IV = intravenous; Q3W = once every 3 weeks.
            `} 
        />

    </ComponentWrapper>
);

export default NMIBC;
